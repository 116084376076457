import {List, Set} from 'immutable'
import moment from 'moment'
import React from 'react'
import {detect} from 'detect-browser'


export const capitalize = (word, firstLetter = true) => (firstLetter ? word.charAt(0).toUpperCase() : word.charAt(0)) + word.slice(1).replace(/(_\w)/g, match => match[1].toUpperCase())

/**
 * Returns the word for the number passed.
 *
 * @param {number} number to be converted
 *
 * @returns {string}
 */
export const numberToWord = number => {
  const numberWords = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten'
  ]

  return numberWords[number]
}

export const removeDashes = string => (string.replace(/_|-/g, ' '))

export const removeDashesAndCapitalize = string => (capitalize(removeDashes(string)))

// this helper does a shallow comparison and not suitable for any deep comparison eg. comparison between array of objects - Atanda
export const arraysAreEqual = (previousArray, newArray) => {
  if ((previousArray && newArray) instanceof Array && previousArray.sort().toString() === newArray.sort().toString())
    return true
  return false
}

export const dasherizedToCamelCase = input => input.toLowerCase().replace(/-(.)/g, (match, group1) => group1.toUpperCase())

export const addPossessive = name => {
  if (!name)
    return 'their'

  const trimmedName = name.trim()
  return (/s$/i).test(trimmedName) ? `${trimmedName}'` : `${trimmedName}'s`
}

export const isValidDate = date => (Object.prototype.toString.call(date) === '[object Date]') && isFinite(date)

export const isValidBirthdate = birthdate => isValidDate(birthdate) && moment.utc().subtract(18, 'years') > birthdate// No longer assumes that birthdate is a date --NP

export const isValidProfile = profile => (profile && !$.isEmptyObject(profile) && (profile.zip || profile.country_code) && isValidBirthdate(profile.dob)) === true

export function noop() {}

export const objectsAreEqual = (objectOne, objectTwo) => JSON.stringify(objectOne) === JSON.stringify(objectTwo)

export const pluralize = (item, count, pluralOverride = null) => { // Assumes zero should be plural (e.g., '0 clients')
  if (count === 1)
    return item

  if (pluralOverride)
    return pluralOverride

  return `${item}s`
}

export const randomElement = inputArray => {
  const length = inputArray.length
  switch (length) {
    case 0:
      return
    case 1:
      return inputArray[0]
    default:
      return inputArray[Math.floor(Math.random() * inputArray.length)]
  }
}

export class RenderNullComponent extends React.Component {
  render() { return null }
}

export const sortByKey = (objects, key) => objects.sort((one, two) => (one[key] > two[key]))

export const allUsersDeceased = users => {
  // Just bail if missing props or something else caused null, undefined, or an empty array to be passed to this method:
  if (!users || !users.length)
    return false

  return users.map(user => user.deceased_at).every(deathDate => deathDate) // Dates are truthy, while null and undefined are falsey. --BLR
}

/**
 * This genrates a UUID and removes all the dashes 'cause randonBytes doesn't exist in the browser context
 * @returns {String}
 */
export const secureRandom = () => crypto.randomUUID().replace(/-/g, '')

/**
 * Generate new sessionId if it's not present in sessionStorage and stores it in sessionStorage
 * @returns {String}
 */
export const generateSessionId = () => {
  let sessionId = sessionStorage.getItem('sessionId')

  if (!sessionId) {
    sessionId = secureRandom()
    sessionStorage.setItem('sessionId', sessionId)
  }

  return sessionId
}

/**
 * checks if the browser is among the browser we are supporting
 * @returns {Boolean}
 */
export const supportedBrowser = () => {
  const browser = detect()
  // ios is to detect mobile safari and crios is to detect mobile chrome
  const SUPPORTED_BROWSERS = ['chrome', 'safari', 'ios', 'crios', 'edge-chromium', 'firefox', 'edge']

  return SUPPORTED_BROWSERS.includes(browser.name)
}

/**
 * checks if the browser is IE11
 * @returns {Boolean}
 */
export const isInternetExplorer11 = () => {
  const browser = detect()
  return browser.name === 'ie' && browser.version === '11.0.0'
}

/**
 * Returns the number of assessment questions that has truthy value
 * @param {Map} assessmentData
 * @returns {Number}
 */
export const numberOfAssessmentQuestionsAnswered = assessmentData => (assessmentData.filter(value => value).size)

/**
 * Return response with their attributes without `attributes` key
 * This is only used in components that uses ReactTable
 * @param {Array} responses
 * @returns {Array}
 */
export const cleanApiResponseData = responses => responses && responses.map(response => ({...response.attributes, id: response.id}))

export const isEnagagementAutomated = organization => organization && organization.data.organization_client_invite === 'engagement_automation'

export const numberWithCommas = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const transformKey = (key, data) => {
  if (key === 'final-ceremony')
    return data.has('headstone') ? 'headstone' : 'ashes'
  else
    return key
}

export const checkboxValues = data => {
  const GRAND_AND_INTIMATE_CHECKBOX_VALUES = List(['Stationery (Guest Book, Cards)', 'Officiant (Either Donation or Payment)'])
  let values = List(['Death certificates', 'Obituary'])

  if (data.get('celebration') === 'A grand affair') {
    values = List([
      ...values,
      ...GRAND_AND_INTIMATE_CHECKBOX_VALUES,
      'Viewing or visitation',
      'Post ceremony reception',
      'Flowers',
      'Hearse',
      'Limousine / car service'
    ])
  } else if (data.get('celebration') === 'An intimate ceremony') {
    values = List([...values, ...GRAND_AND_INTIMATE_CHECKBOX_VALUES])
  }

  if (data.get('proximity-to-home') === 'We got some ground to cover')
    values = List([...values, 'Air transportation of the body'])

  return data.get('additional-expenses', values)
}

/**
 * Returns the keys that does not exist in both immutable Maps
 * @param {Map} current
 * @param {Map} updated
 * @returns {Set<String>}
 */
export const differenceKeys = (current, updated) => {
  const updatedKeys = Set(updated.keys())
  const currentKeys = Set(current.keys())

  return updatedKeys.subtract(currentKeys)
}

/**
 * Extract token from strings in the format +Token token={authToken}+
 * @param {String} authorization - axios authorization string
 */
export const extractAuthToken = authorization => authorization.split(' ')[1].split('=')[1]

/**
 * Returns true if user needs firm organization config to determine whether to allow skipping TFA intro
 * @param {{client: Boolean, 'everplan-id': Number, premium: Boolean}} userConfig
 */
export const needsFirmOrganizationConfig = userConfig => userConfig.client || (!!userConfig['everplan-id'] && !userConfig.premium)

export const debounce = (func, wait) => {
  let timeoutId

  return (...parameters) => {
    const callFunctionWithArguments = () => {
      clearTimeout(timeoutId)
      func(...parameters)
    }

    clearTimeout(timeoutId)

    timeoutId = setTimeout(callFunctionWithArguments, wait)
  }
}

/**
 * Returns a payment messaging object for a new subscription
 * @param {{suppress_trial: Boolean, name: String, first_year_additional_fee: Number}} partner - current user's partner
 * @returns {{buttonText: String, headline: String, subtitle: String}}
 */
export const newSubscriptionMessaging = partner => {
  let messaging = {buttonText: partner.suppress_trial ? 'Start subscription' : 'Start your free trial'}

  if (partner.first_year_additional_fee > 0) {
    messaging = {...messaging, headline: `Get One Year Of Everplans Premium + ${partner.name}`, subtitle: 'Enter your credit card below.'}
  } else {
    messaging = {
      ...messaging,
      headline: 'Just one more step.',
      subtitle: `Enter your credit card ${partner.suppress_trial ? 'to start your subscription' : 'information to start your trial'}.`
    }
  }

  return messaging
}

/**
 * Remove all html tags from provided text
 * @param {String} text
 * @returns {String}
 */
export const sanitize = text => text.replace(/<[^>]+>/g, '')

/**
 * Returns list of countries except sanctioned count countries
 * @param {Array<{code: String, name: String}>|List<Map>} countries - list of countries
 * @returns {Array<{code: String, name: String}>|List<Map>}
 */
export const filterSanctionedCountries = countries => {
  const sanctionedCountriesCode = ['CU', 'IR', 'KP', 'RU', 'SY']

  return countries.filter(country => !sanctionedCountriesCode.includes(country.code || country.get('value')))
}

export const compareInt = (firstInt, secondInt, base = 10) =>
  parseInt(firstInt, base) === parseInt(secondInt, base)

export const formatNumber = backupCode => {
  let buffer = ''

  if (backupCode) {
    for (let position = 0; position < backupCode.length; position += 4)
      buffer += `${backupCode.substring(position, position + 4)} `
  }
  return buffer
}
