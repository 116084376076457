import classnames from 'classnames'
import PropTypes from 'prop-types'

import FeatureFlags from '../lib/FeatureFlags'
import {noop} from '../lib/tools'
import * as urlTools from '../lib/urlTools'

export const Link = props => {
  // This logic was moved outside of return statement for clarity:
  const className = props.className ? props.className : ''
  const href = props.href ? props.href : ''
  const onClick = props.onClick ? props.onClick : noop
  const target = props.target ? props.target : '_self'
  const children = props.children ? props.children : 'MISSING LINK CHILDREN'

  return <a className={className} href={href} onClick={onClick} target={target}>{children}</a>
}

Link.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.oneOf(['_blank', '_parent', '_self', '_top'])
}

// Generic link types:
export const ControlLink = props => Link(Object.assign({}, props, {className: classnames(props.className, 'control-link', {'padding-left-base': props.padLeft})}))


// Page-specific links:
export const HomePage = props => Link({
  children: (props.children ? props.children : 'Home Page'),
  className: props.className,
  href: '//www.everplans.com'
})

export const Help = props => Link({
  children: (props.children ? props.children : 'Help'),
  className: props.className,
  href: urlTools.helpUrl(props),
  onClick: props.onClick,
  target: '_blank'
})

export const ContactUs = props => {
  const contactUrl = (
    FeatureFlags.isEnabled('redirect_help') ?
      'https://www.everplans.com/everplans-most-helpful-help-topics' :
      'https://help.everplans.com/customer/portal/emails/new'
  )

  return Link({
    children: (props.children ? props.children : 'Contact Us'),
    className: props.className,
    href: contactUrl,
    onClick: props.onClick,
    target: '_blank'
  })
}

// HELP LINKS:
export const ProHelp = props => Help({
  children: (props.children ? props.children : 'Help'),
  className: props.className,
  onClick: props.onClick,
  advisor: true,
  target: props.target
})

export const ProResourceCenter = props => Help({
  advisorResources: true,
  children: (props.children ? props.children : 'Pro Resource Center'),
  className: props.className,
  onClick: props.onClick,
  target: props.target
})

// OTHER LINKS:
export const AccountSettings = props => Link({
  children: (props.children ? props.children : 'Account Settings'),
  className: props.className,
  href: urlTools.accountSettingsUrl,
  onClick: props.onClick,
  target: props.target
})

export const LogIn = props => Link({
  children: (props.children ? props.children : 'Log in'),
  className: props.className,
  href: API_HOST,
  onClick: props.onClick,
  target: props.target
})

export const JiclLogIn = props => Link({
  children: (props.children ? props.children : 'Access Your Just-In-Case List'),
  className: props.className,
  href: `${WEBAPPS_HOST}/#/jicl/continue`,
  onClick: props.onClick,
  target: props.target
})

export const MyPersonalEverplan = props => Link({
  children: (props.children ? props.children : 'Go to my personal Everplan »'),
  className: props.className,
  href: (props.currentUser ? urlTools.corpMaxDashboardUrl : '#'),
  onClick: props.onClick,
  target: props.target
})

export const MyProDashboard = props => Link({
  children: (props.children ? props.children : 'Go to my Professional Dashboard »'),
  className: props.className,
  href: `${WEBAPPS_HOST}/#/pro/clients`,
  onClick: props.onClick,
  target: props.target
})

export const MyAccountManagerDashboard = props => Link({
  children: (props.children ? props.children : 'Go to my Account Manager Dashboard »'),
  className: props.className,
  href: `${WEBAPPS_HOST}/#/pro/firm-admin/all-firms`,
  onClick: props.onClick,
  target: props.target
})

export const MyQuarterbackDashboard = props => Link({
  children: (props.children ? props.children : 'Go to my Quarterback Dashboard »'),
  className: props.className,
  href: `${WEBAPPS_HOST}/#/pro/quarterback-admin/clients`,
  onClick: props.onClick,
  target: props.target
})

export const SignOut = props => Link({
  children: (props.children ? props.children : 'Sign Out'),
  className: props.className,
  href: urlTools.railsUrl('sign_out'),
  onClick: props.onClick,
  target: props.target
})

export const SignUp = props => Link({
  children: (props.children ? props.children : 'Sign up'),
  className: props.className,
  href: `${WEBAPPS_HOST}/#/auth/sign-up`,
  onClick: props.onClick,
  target: props.target
})

export const Terms = props => Link({
  children: (props.children ? props.children : 'Terms of Service'),
  className: props.className,
  href: `${CONTENT_HOST}/terms-conditions`,
  onClick: props.onClick,
  target: props.target
})

export const Privacy = props => Link({
  children: (props.children ? props.children : 'Privacy'),
  className: props.className,
  href: `${CONTENT_HOST}/privacy-policy`,
  onClick: props.onClick,
  target: props.target
})

export const Security = props => Link({
  children: (props.children ? props.children : 'Security'),
  className: props.className,
  href: `${CONTENT_HOST}/everplans-security`,
  onClick: props.onClick,
  target: props.target
})


export const ResourcesGuides = props => Link({
  children: (props.children ? props.children : 'Resources and Guides'),
  className: props.className,
  href: 'https://www.everplans.com/topics',
  onClick: props.onClick,
  target: props.target
})
